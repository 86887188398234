<template>
    <div class="error-page flex gap-3 flex-col my-48 text-center">
      <h1 class="text-nomad-accent font-bold  text-3xl uppercase">404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <div><router-link to="/" class="rounded-full border-2 border-nomad-primary py-3 px-4 inline-block mt-8 font-bold hover:bg-nomad-accent">Go to Home</router-link>
    </div></div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
  }
  </script>
  

<template>
    <div class="rounded-2xl" :style="{backgroundColor: color, color: textColor}">
        <div class="flex flex-col lg:flex-row items-center">
            <div class="text-center  w-full min-w-3xl">
                <img :src="image" class="rounded-t-2xl w-80 object-cover object-top h-96 mx-auto mt-16" alt="">
            </div>
            <div class="flex flex-col text-left p-6 gap-6">
                <div class="flex gap-4">
                    <div v-for="stat in stats">
                        <div class="font-thin text-3xl">{{stat.number}}</div>
                        <div class="font-thin text-xl">{{stat.context}}</div>
                    </div>
                 
                </div>
                <div class="text-4xl font-medium">{{ name }}</div>
                <div class="text-2xl font-light hidden md:block">{{ description }}</div>
                <div class="hidden"><button-link>Visit<span class="icon-external-link ml-1"></span></button-link></div>
            </div>
        </div>
    </div>
</template>
<script>
import Menu from '@/components/buttons/Menu.vue'
import ButtonLink from '@/components/buttons/ButtonLink.vue';
export default {
    props: {
        name: String,
        description: String,
        url: String,
        image: String,
        stats: Array,
        color: {
            type: String,
            default: '#E4E4E4'
        },
        textColor: {
            type: String,
            default: '#fff'
        }
    },
    components: {
        Menu,
        ButtonLink
    }
}
</script>
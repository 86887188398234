<template>
    <div class="bg-nomad-light  w-full" v-bind:style="{ minHeight: '1200px' }">
        <div class="container p-8 lg:p-0 flex flex-col lg:flex-row gap-10 mx-auto items-center mb-20">
            <div class="flex gap-4 flex-col flex-1">
                <div><router-link to="/checkout" class="font-bold border rounded-full border-nomad-primary hover:bg-nomad-primary hover:text-nomad-light p-3">Book In-Hurry <span class="icon-arrow-right"></span></router-link></div>
                <h1 class="text-6xl lg:text-8xl font-bold text-nomad-primary">Elevate Your Business with Tailored Digital Solutions</h1>
                <p class="text-light text-2xl">Experience the Difference with Our Expertise in Web Development, Mobile Apps, Marketing, and Business Consulting</p>
                <div class="flex gap-4">
                    <button-link url="/plans">See Plans</button-link>
                </div>
            </div>
            <div class="text-center flex-1">
                <img src="/assets/images/screen-discover.svg" class="m-auto border-3 rounded-xl border-white" alt=""
                    style="width: 32rem;">
            </div>
        </div>
    </div>
    <Container>
        <C2a title="In-Hurry" subtitle="Our In-Hurry plan is the fastest way to get your website live. We'll build you a beautiful, minimalist site that's optimized for speed and user experience. This plan is perfect if you need a simple, yet effective online presence quickly." cta="Pay $839" url="/checkout" />
    </Container>
    <Container>
        <Trusted />
    </Container>
    <Container>
        <Projects leading="SERVICES" title="Visionary Solutions: Navigating from Concept to Success." description="At Nexa Interactives, we're dedicated to empowering small businesses like yours to thrive in the digital world. Whether you're in need of a professional website, effective advertising campaigns, or engaging social media marketing, we're here to help you achieve your goals." cta="Our Services" url="/services" :contents="contents" />
    </Container>
    <Container>
        <C2a title="We are local" subtitle="Have a question or need assistance? With Nexa Interactives, you'll never have to worry about time zone differences or language barriers. Our local team is just a phone call or short drive away, providing responsive support when you need it most." cta="Pick a Plan" url="/plans" />
    </Container>
    <Container class="flex flex-col gap-8">
        <Projects leading="PROJECTS" title="Navigating from Vision to Success: Our Portfolio of Achievements" description="Explore a selection of our standout projects that showcase our expertise and creativity. From web design to app development, these success stories highlight our commitment to delivering exceptional digital solutions" cta="All Projects" url="/projects" />
        <ProjectPreview v-for="project in projects" v-bind:key="project.id" :color="project.color" :image="project.image" :stats="project.stats" :name="project.name" :description="project.description" />
        <Projects leading="PLANS" title="Tailored Solutions to Propel Your Success" description="Explore our range of tailored plans designed to meet your specific needs. Whether you're just starting or looking to elevate your digital presence, our plans offer a pathway to success. Choose the one that aligns with your goals and embark on a journey to digital excellence with Nexa Interactives." cta="All Plans" url="/plans" />
        <PlansPreview />
    </Container>
    <div class="bg-black py-10">
        <Container>
            <Testimonials />
        </Container>
    </div>
</template>
<script>
import ButtonLink from '@/components/buttons/ButtonLink.vue';
import ProfileWImage from '@/components/profile/ProfileWImage.vue'
import Trusted from '@/components/Trusted.vue';
import Projects from '@/components/Projects.vue';
import C2a from '@/components/C2a.vue';
import Container from '@/layouts/Container.vue';
import ProjectPreview from '@/components/ProjectPreview.vue';
import PlansPreview from '@/components/PlansPreview.vue';
import Testimonials from '@/components/Testimonials.vue';
export default {
    components: {
    ButtonLink,
    ProfileWImage,
    Trusted,
    Projects,
    C2a,
    Container,
    ProjectPreview,
    PlansPreview,
    Testimonials
},
    data() {
        return {
            contents: [
                {
                    id: 1,
                    title: 'Tailored Solutions',
                    description: "We understand the unique challenges and opportunities faced by small businesses. Our team specializes in crafting custom solutions that align with your specific needs and budget, ensuring maximum impact for your investment.",
                    icon: '/assets/planing.svg'
                },
                {
                    id: 2,
                    title: 'Comprehensive Services',
                    description: "From website design and development to advertising and social media marketing, we offer a full suite of digital services to support your business growth. Whether you're just starting out or looking to expand your online presence, we've got you covered.",
                    icon: '/assets/code.svg'
                },
                {
                    id: 3,
                    title: 'Local Expertise',
                    description: "Based in Annandale, Virginia, we're your trusted local partner for all things digital. With a deep understanding of the local & digital market landscape and years of experience working with businesses in our community, we're well-equipped to help you succeed.",
                    icon: '/assets/search.svg'
                }
            ],
            projects: [
                {
                    id: 1,
                    name: 'Flex Cleaning Service',
                    description: "Flex Cleaning's transformation journey includes a modern website, data insights, and targeted reach. We crafted a website that reflects their professionalism and implemented data tools for strategic decisions. Our targeted reach system maximizes their online presence.",
                    color: '#787293',
                    url: '',
                    image: '/assets/images/screen-fc.png',
                    stats: [{number: '5x', context: 'branches'}, {number: '700K', context: 'transactions'}]
                },
                {
                    id: 2,
                    name: 'Mrembo Skin',
                    description: "Mrembo Skin Care's digital evolution brought forth a Shopify website, tailored to perfection. Our collaboration ensured a seamless e-commerce experience, showcasing Mrembo's products with finesse.",
                    color: '#275C53',
                    url: '',
                    image: '/assets/images/screen-mrembo.png',
                    stats: [{number: '15k', context: 'transactions'}]
                },
                {
                    id: 3,
                    name: 'Nomad Lyrics',
                    description: "Nomad Lyrics underwent a digital transformation with Nexa Interactives, receiving a sleek website and customized iOS and Android apps. Our collaboration ensured seamless user experiences and elevated brand presence across platforms.",
                    color: '#2A2033',
                    textColor: "#ffffff",
                    url: '',
                    image: '/assets/images/screen-discover.png',
                    stats: [{number: '1.2M', context: 'streams'}, {number: '100K', context: 'visits'}]
                }
            ]
        }
    }
}
</script>
<template>
    <div class="bg-nomad-greenish  w-full">
        <div class="container p-8 lg:p-0 flex flex-col lg:flex-row gap-10 mx-auto items-center mb-20">
            <div class="flex gap-4 flex-col flex-1">
                <h1 class="text-6xl lg:text-8xl font-bold text-nomad-primary">Checkout</h1>
                <p class="text-light text-2xl">Welcome to our streamlined checkout process. Here, you can finalize your
                    digital journey with us. Review your selections, provide essential information, and proceed to the next
                    phase of our collaboration. We're here to make your digital aspirations a reality. Thank you for
                    choosing Nexa Interactives.</p>
            </div>
            <div class="text-center flex-1">
                <img src="/assets/images/receipt.svg" class="m-auto border-3 rounded-xl border-white" alt=""
                    style="width: 32rem;">
            </div>
        </div>
    </div>
    <Container class="flex flex-col gap-8">
        <form action="#" method="POST" class="space-y-6" v-on:submit.prevent="_checkout">
            <div>
                <ul class="flex flex-col lg:flex-row gap-4">
                    <li v-for="plan in plans">
                        <label
                            class="relative  cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none flex gap-3 justify-between"
                            :for="'plan_id_' + plan.id">
                            <input type="radio" name="server-size" :id="'plan_id_' + plan.id" :value="plan"
                                v-model="form.plan" class="sr-only" aria-labelledby="server-size-0-label"
                                aria-describedby="server-size-0-description-0 server-size-0-description-1">
                            <span class="flex items-center">
                                <span class="icon-circle text-2xl mr-3"
                                    :class="{ 'bg-nomad-grass rounded-full': (form.plan?.id == plan.id) }"></span>
                                <span class="flex flex-col text-lg">
                                    <span id="server-size-0-label" class="font-medium uppercase text-gray-900">{{ plan.name
                                    }}</span>
                                    <span id="server-size-0-description-0" class="text-gray-700 text-md">
                                        <span class="block sm:inline text-sm">{{ plan.sold }}</span>
                                    </span>
                                </span>
                            </span>
                            <span id="server-size-0-description-1"
                                class="mt-2 flex text-lg sm:ml-4 sm:mt-0 sm:text-right items-center">
                                <span class="font-medium text-gray-900">${{ plan.priceFormatted }}</span>
                            </span>
                            <span
                                class="pointer-events-none absolute -inset-px rounded-lg border-4 transition-all duration-300"
                                :class="{ 'border-nomad-grass border': (form.plan?.id == plan.id) }"
                                aria-hidden="true"></span>
                        </label>
                    </li>
                </ul>
            </div>
            <div v-if="form.plan" class="lg:grid-cols-3 grid gap-8">
                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 col-span-2">
                    <div v-if="form.plan && form.plan.options">
                        <label for="first-name" class="block text-lg font-nomral leading-6 text-gray-900">Work Type</label>
                        <div class="mt-2.5">
                            <select name="first-name" id="first-name" autocomplete="given-name" v-model="form.suggested"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6">
                                <option v-for="option in form.plan.options" :value="option">{{ option.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="form.plan && form.plan.options">
                        <label for="estimated-hours" class="block text-lg font-nomral leading-6 text-gray-900">Estimated
                            Hours <span class="text-red-600 font-bold"
                                v-if="errors && errors.errors && errors.errors.hours">*</span></label>
                        <div class="mt-2.5">
                            <input type="number" name="estimated-hours" id="estimated-hours" autocomplete="estimated-hours"
                                v-model="form.hours"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="full-name" class="block text-lg font-nomral leading-6 text-gray-900">Full name <span
                                class="text-red-600 font-bold"
                                v-if="errors && errors.errors && errors.errors.fullName">*</span></label>
                        <div class="mt-2.5">
                            <input type="text" name="full-name" id="full-name" autocomplete="given-name"
                                v-model="form.fullName"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="email" class="block text-lg font-nomral leading-6 text-gray-900">Email <span
                                class="text-red-600 font-bold"
                                v-if="errors && errors.errors && errors.errors.email">*</span></label>
                        <div class="mt-2.5">
                            <input type="email" name="email" id="email" autocomplete="email" v-model="form.email"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="phone" class="block text-lg font-nomral leading-6 text-gray-900">Phone <span
                                class="text-red-600 font-bold"
                                v-if="errors && errors.errors && errors.errors.phone">*</span></label>
                        <div class="mt-2.5">
                            <input type="text" name="phone" id="phone" autocomplete="phone" v-model="form.phone"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="zipcode" class="block text-lg font-nomral leading-6 text-gray-900">Zip/Postal
                            code <span class="text-red-600 font-bold"
                                v-if="errors && errors.errors && errors.errors.zipcode">*</span></label>
                        <div class="mt-2.5">
                            <input type="text" name="zipcode" id="zipcode" autocomplete="zipcode" v-model="form.zipcode"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div class="col-start-1 col-end-1">
                        <label for="agreement"
                            class="text-lg font-nomral leading-6 text-gray-900 mt-3 flex items-center gap-3 cursor-pointer">
                            <input type="checkbox" name="agreement" id="agreement" class="sr-only" v-model="form.agreement">
                            <span
                                class="icon-toggle-left text-3xl font-thin text-nomad-primary  transition-all duration-1000 rounded-xl"
                                :class="{ 'icon-toggle-right bg-nomad-grass': form.agreement }"></span> <span>By selecting
                                this, you agree to our privacy policy.</span>
                        </label>
                    </div>
                    <div class="col-start-1 col-end-1">
                        <button
                            class="bg-nomad-grass rounded-3xl font-medium text-xl border-2 border-nomad-primary px-3 py-2 flex items-center gap-3 group hover:scale-105 transition-all"><span>Continue</span>
                            <span class="icon-chevron-right group-hover:icon-arrow-right"></span></button>
                    </div>
                </div>
                <div
                    class="border-4 border-nomad-grass rounded-xl flex items-center col-span-1 fixed md:relative bottom-0 md:bottom-auto  left-0 md:left-auto w-full bg-white z-40">
                    <div class="text-center font-bold text-4xl mx-auto flex items-center gap-1 my-6" v-if="form.plan">
                        <span>$</span> <span class="font-light" v-if="form.plan && form.plan.options">{{ form.plan.price *
                            form.hours }}</span><span v-else class="font-light">
                            <div class="flex gap-4 lg:flex-col text-left pl-3">
                                <div class="flex flex-col"><span class="text-sm uppercase">due now</span>{{ form.plan.downpayment }}</div>
                                <div class="flex flex-col text-gray-500"><span class="text-sm uppercase">total</span>{{ form.plan.priceFormatted}}</div>
                            </div>
                        </span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div v-if="errors && errors.message">
                <span class="text-red-700 font-bold">{{ errors.message }}</span>
            </div>
        </form>
    </Container>
    <div class="bg-black py-10 mt-28">
        <Container>
            <Testimonials />
        </Container>
    </div>
</template>
<script setup>
// import { usePrecision } from '@vueuse/math'

</script>
<script>
import axios from 'axios'
import ButtonLink from '@/components/buttons/ButtonLink.vue';
import ProfileWImage from '@/components/profile/ProfileWImage.vue'
import C2a from '@/components/C2a.vue';
import Container from '@/layouts/Container.vue';
import Testimonials from '@/components/Testimonials.vue';
export default {
    components: {
        ButtonLink,
        C2a,
        Container,
        Testimonials
    },
    data() {
        return {
            form: {
                plan: null,
                planId: null,
                fullName: null,
                email: null,
                phone: null,
                zipcode: null,
                agreement: null,
                hours: null
            },
            errors: null,
            plans: [
                {
                    id: 1,
                    name: 'In-Hurry',
                    type: '',
                    downpayment: '500',
                    slug: 'in-hurry',
                    price: 839,
                    priceFormatted: '839',
                    sold: 'Get your business online fast with our In-Hurry plan.'
                },
                {
                    id: 2,
                    name: 'Starter Spark',
                    type: '',
                    downpayment: '1,292.85',
                    slug: 'starter-spark',
                    price: 1989,
                    priceFormatted: '1,989',
                    sold: 'Ideal for Small Businesses Aiming High!'
                },
                {
                    id: 3,
                    name: 'Growth Gear',
                    downpayment: '3,293.95',
                    slug: 'growth-gear',
                    price: 5989,
                    priceFormatted: '5,989',
                    sold: 'Perfect for Growing Your Business Further!'
                },
                {
                    id: 4,
                    name: 'Hourly Subscription',
                    downpayment: 72.5,
                    slug: 'hourly-subscription',
                    price: 72.5,
                    priceFormatted: '72.5',
                    sold: '100s of hours booked monthly for all types of services!',
                    options: [
                        {
                            id: 2,
                            name: 'Business Consulting',
                            average: 7

                        },
                        {
                            id: 1,
                            name: 'Search Enginge Optimization',
                            average: 16
                        },
                        {
                            id: 3,
                            name: 'Email Marketing',
                            average: 9

                        },
                        {
                            id: 2,
                            name: 'Lead Generation',
                            average: 8

                        },
                        {
                            id: 2,
                            name: 'Flyer Design (100 count)',
                            average: 3

                        },
                        {
                            id: 2,
                            name: 'Business Card (100 count)',
                            average: 2

                        },
                        {
                            id: 2,
                            name: 'Other services',
                            average: 1

                        },
                    ]
                }
            ],
            projects: [
                {
                    id: 1,
                    name: 'Flex Cleaning Service',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D6D3E2',
                    url: '',
                    image: '/assets/images/screen-fc.png',
                    stats: [{ number: '5x', context: 'branches' }, { number: '700K', context: 'transactions' }]
                },
                {
                    id: 2,
                    name: 'Horn Daily',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D5CB97',
                    url: '',
                    image: '/assets/images/screen-hd.png',
                    stats: [{ number: '200k', context: 'articles' }, { number: '150K', context: 'visits' }]
                },
                {
                    id: 3,
                    name: 'Nomad Lyrics',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#97D5B4',
                    url: '',
                    image: '/assets/images/screen-discover.png',
                    stats: [{ number: '1.2M', context: 'streams' }, { number: '100K', context: 'visits' }]
                }
            ]
        }
    },
    created() {
        this.$watch('form.suggested', (to, from) => {
            if (from !== to) {
                this.form.hours = to.average; // Access and modify component data
            }
        });
    },
    methods: {
        _checkout() {
            axios.post('/checkout-continue', this.form)
                .then((response) => {
                    window.location = response.data.paymentLink
                })
                .catch((error) => {
                    this.errors = error.response.data
                })
        }
    }
}
</script>
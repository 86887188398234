<template>
    <div class="bg-nomad-greenish  w-full">
        <div class="container p-8 lg:p-0 flex flex-col lg:flex-row gap-10 mx-auto items-center mb-20">
            <div class="flex gap-4 flex-col flex-1">
                <span class="text-xl font-light">CONTACT US</span>
                <h1 class="text-6xl lg:text-8xl font-bold text-nomad-primary">Let's Talk: <span
                        class="text-nomad-accent">Discover Nexa Interactives</span></h1>
                <p class="text-light text-2xl  leading-loose">Empowering Businesses and Visionaries to Transform Ideas Into
                    Innovations and Aspirations Into Achievements in a Connected World</p>
                <ul class="">
                    <li v-for="content in contents">

                    </li>
                </ul>
            </div>
            <div class="text-center flex-1">
                <img src="/assets/contact.svg" class="m-auto border-3 rounded-xl border-white" alt="" style="width: 32rem;">
            </div>
        </div>
    </div>
    <Container class="grid grid-cols-1 md:grid-cols-12 gap-8">
        <div class="col-span-1 md:col-span-6">
            <h2 class="text-xl font-bold">Get in touch</h2>
            <p class="text-lg font-light leading-loose">At Nexa Interactives, we're just a message away. Reach out to us
                with your questions, ideas, or project inquiries. We're here to assist you promptly and make your digital
                goals a reality.</p>
            <hr class="my-6 w-28" />
            <ul class="my-3 text-md font-light space-y-4">
                <li><span class="icon-map-pin"></span> Washington, DC | Minneapolis, MN</li>
                <li><span class="icon-phone"></span> +1 (202) 670-1716</li>
                <li><span class="icon-mail"></span> support@nexainteractives.com</li>
            </ul>
        </div>
        <div class="col-span-1 md:col-span-6">
            <form action="#" v-on:submit.prevent="_submit">
                <div class="space-y-5">
                    <div>
                        <label for="full-name" class="block text-md font-nomral leading-6 text-gray-500">Full name <span
                                class="text-red-600 font-bold">*</span></label>
                        <div class="mt-2.5">
                            <input type="text" name="full-name" id="full-name" autocomplete="given-name" v-model="form.fullName"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 focus:shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="email" class="block text-md font-nomral leading-6 text-gray-500">Email <span
                                class="text-red-600 font-bold">*</span></label>
                        <div class="mt-2.5">
                            <input type="text" name="email" id="email" autocomplete="email" v-model="form.email"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 focus:shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="phone" class="block text-md font-nomral leading-6 text-gray-500">Phone <span
                                class="text-red-600 font-bold">*</span></label>
                        <div class="mt-2.5">
                            <input type="text" name="phone" id="phone" autocomplete="phone" v-model="form.phone"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 focus:shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6" />
                        </div>
                    </div>
                    <div>
                        <label for="message" class="block text-md font-nomral leading-6 text-gray-500">Message <span
                                class="text-red-600 font-bold">*</span></label>
                        <div class="mt-2.5">
                            <textarea name="message" id="message" v-model="form.message"
                                class="block  w-full rounded-md border-0 px-3.5 py-4 text-gray-900 focus:shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:ring-nomad-grass sm:text-xl sm:leading-6"></textarea>
                        </div>
                    </div>
                    <div class="col-start-1 col-end-1" v-if="errors && errors.message">
                        <div class="text-red-700 font-bold text-sm">{{ errors.message }}</div>
                    </div>
                    <div class="col-start-1 col-end-1" v-if="message">
                        <div class="text-gray-900 font-bold text-sm">{{ message }}</div>
                    </div>
                    <div class="col-start-1 col-end-1">
                        <button :disabled="(!form.fullName || !form.email || !form.phone || !form.message) && !loading"
                            class="bg-nomad-grass disabled:opacity-50 rounded-3xl font-medium text-lg border-2 border-nomad-primary px-3 py-2 flex items-center gap-3 group hover:scale-105 disabled:scale-100 transition-all"><span class="flex  gap-3 items-center"><span>Send</span> <span class="dot-pulse mx-3" v-if="loading"></span></span>
                            <span class="icon-chevron-right group-hover:icon-arrow-right"></span></button>
                    </div>
                </div>
            </form>
        </div>
    </Container>
    <Container>
        <C2a title="Hourly Support Subscription"
            subtitle="Access Dedicated Assistance and Support at an Average Rate of $72.8 This Year" cta="Pay now"
            url="/" />
    </Container>
</template>
<script>
import ButtonLink from '@/components/buttons/ButtonLink.vue';
import axios from 'axios'
import C2a from '@/components/C2a.vue';
import Container from '@/layouts/Container.vue';


export default {
    components: {
        ButtonLink,
        C2a,
        Container,
    },
    data() {
        return {
            loading: false,
            form: {
                fullName: null,
                email: '',
                phone: '',
                message: ''
            },
            message: null,
            errors: null,
            contents: [
                {
                    id: 1,
                    title: 'Research & planing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/star.svg'
                },
                {
                    id: 2,
                    title: 'Design & Development',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/code.svg'
                },
                {
                    id: 3,
                    title: 'SEO & Marketing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/zap-off.svg'
                }
            ],
            projects: [
                {
                    id: 1,
                    name: 'Flex Cleaning Service',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D6D3E2',
                    url: '',
                    image: '/assets/images/screen-fc.png',
                    stats: [{ number: '5x', context: 'branches' }, { number: '700K', context: 'transactions' }]
                },
                {
                    id: 2,
                    name: 'Horn Daily',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D5CB97',
                    url: '',
                    image: '/assets/images/screen-hd.png',
                    stats: [{ number: '200k', context: 'articles' }, { number: '150K', context: 'visits' }]
                },
                {
                    id: 3,
                    name: 'Nomad Lyrics',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#97D5B4',
                    url: '',
                    image: '/assets/images/screen-discover.png',
                    stats: [{ number: '1.2M', context: 'streams' }, { number: '100K', context: 'visits' }]
                }
            ]
        }
    },
    methods: {
        _submit() {
            this.loading = true
            axios.post('/send_mail', this.form)
                .then((response) => {
                    this.loading = false
                    this.message =  response.data.message
                    this.errors = null
                    this.form  = {
                            fullName: null,
                            email: null,
                            phone: null,
                            message: null
                        }
                })
                .catch((error) => {
                    this.loading = false
                    this.errors = error.response.data
                })
        }
    }
}
</script>
<template>
    <div>
        <Projects leading="TESTIMONIALS" color="text-white" title="What Our Clients Say."
            description="Discover the experiences of our satisfied clients who have entrusted us with their digital journey. Read their testimonials and see why they choose Nexa Interactives for exceptional service and results"
            cta="All Reviews" />
        <div>
            <ul>
                <carousel :breakpoints="breakpoints" snap-align="start">
                    <slide v-for="review in reviews" :key="review.id">
                        <div class="rounded-xl bg-white mx-4 p-6">
                            <p class="text-lg font-light text-left mb-4" v-html="review.content"></p>
                            <ProfileWImage class="text-left" :image="review.image" :name="review.author"
                                :title="review.title" />
                        </div>
                    </slide>
                </carousel>
            </ul>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import Projects from '@/components/Projects.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import ProfileWImage from './profile/ProfileWImage.vue';
export default {
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Projects,
        ProfileWImage
    },
    data() {
        return {
            breakpoints: {
                700: {
                    itemsToShow: 2.5,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3.5,
                    snapAlign: 'start',
                },
            },
            reviews: [
                {
                    id: 1,
                    content: "Nexa Interactives exceeded our expectations! Their team not only delivered a stunning website but also provided valuable insights to enhance our online presence. Their dedication to our project was truly commendable.",
                    author: "James T",
                    title: "Founder of Melisey",
                    image: "/assets/icons/message-circle.svg"
                },
                {
                    id: 2,
                    content: "Working with Nexa Interactives has been a game-changer for our business. Their expertise in web development and digital marketing has significantly boosted our online visibility. We look forward to more successful collaborations!",
                    author: "Najma M",
                    title: "President of Midwest Rehabs",
                    image: "/assets/icons/message-circle.svg"
                },
                {
                    id: 3,
                    content: "I can't thank Nexa Interactives enough for their outstanding service. They transformed our website into a user-friendly and visually appealing platform. Their attention to detail and responsiveness were remarkable.",
                    author: "Cayni M",
                    title: "President of SOMA Community",
                    image: "/assets/icons/message-circle.svg"
                },
                {
                    id: 4,
                    content: "Nexa Interactives' team is highly professional and skilled. They guided us through the entire app development process, turning our vision into a reality. The app they built has been a game-changer for our business.",
                    author: "Mohammed S",
                    title: "President of Sudanese Community Center",
                    image: "/assets/icons/message-circle.svg"
                },
                {
                    id: 6,
                    content: "Nexa Interactives delivered exceptional results. Their SEO strategies catapulted our website to the top of search rankings. We've seen a significant increase in traffic and conversions, thanks to their expertise.",
                    author: "Salma O",
                    title: "CEO of Wealth Revive",
                    image: "/assets/icons/message-circle.svg"
                }

            ]
        }
    }
}
</script>
<template>
    <div class="flex flex-col lg:flex-row gap-6" :class="color">
        <div class="max-w-xl">
            <div class="font-light text-2xl tracking-widest">{{ leading }}</div>
            <h2 class="font-bold  text-5xl mt-8">{{title}}</h2>
        </div>
        <div class="flex flex-col gap-10 flex-1">
            <div>
                <p class="font-light text-2xl">{{ description }}</p>
            </div>
            <div>
                <button-link class="inline-flex" :url="url">{{ cta }}</button-link>
            </div>
            <div>
                <ul class="grid divide-y  gap-6 list-none">
                    <li v-for="content in contents" class="pt-4">
                        <div class="flex">
                            <div class="w-28 flex items-center text-center">
                                <span class="mx-auto"><img :src="content.icon" class="w-16 fill-nomad-accent" /></span>
                            </div>
                            <div class="flex-1">
                                <div class="uppercase font-medium text-xl">{{ content.title }}</div>
                                <div class="text-light text-lg my-2">{{ content.description }}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import ButtonLink from './buttons/ButtonLink.vue';
export default {
    props: {
        leading: String,
        title: String,
        cta: String,
        description: String,
        url: String,
        color: {
            type: String,
            default: 'text-black'
        },
        contents: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            
        }
    },
    components: {
        ButtonLink
    }
}
</script>

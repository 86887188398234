<template>
    
    <div class="bg-nomad-greenish  w-full" v-bind:style="{ minHeight: '1200px' }">
        <div class="container p-8 lg:p-0 flex flex-col lg:flex-row gap-10 mx-auto items-center mb-20">
            <div class="flex gap-4 flex-col flex-1">
                <span class="text-xl font-light">ABOUT US</span>
                <h1 class="text-6xl lg:text-8xl font-bold text-nomad-primary">Crafting Digital Excellence: <span class="text-nomad-accent">Discover Nexa Interactives</span></h1>
                <p class="text-light text-2xl  leading-loose">Empowering Businesses and Visionaries to Transform Ideas Into Innovations and Aspirations Into Achievements in a Connected World</p>
                <ul class="">
                    <li v-for="content in contents">
                        
                    </li>
                </ul>
            </div>
            <div class="text-center flex-1">
                <img src="/assets/images/discover-mock-draft.svg" class="m-auto border-3 rounded-xl border-white" alt=""
                    style="width: 32rem;">
            </div>
        </div>
    </div>
    <Container class="flex flex-col gap-8">
        <p class="text-xl max-w-4xl leading-loose">At Nexa Interactives, we believe in the transformative power of technology and design. Our journey began with a shared passion for crafting digital experiences that resonate, innovate, and inspire. Today, we stand as a dedicated team of web developers, designers, strategists, and creators united by a common purpose - to bring your digital dreams to life.</p>
        <p class="text-xl max-w-4xl leading-loose">Our mission is simple: to empower businesses, startups, and visionaries like you to thrive in the dynamic digital landscape. We understand that each project is unique, and that's why we approach every challenge with curiosity and creativity. With a foundation built on Python, JavaScript, PHP, Laravel, ReactJS, VueJS and Flutter, our technical expertise is the driving force behind every solution we craft.</p>
        <p  class="text-xl max-w-4xl leading-loose">But we're not just technologists; we're storytellers. We believe that behind every line of code, there's a narrative waiting to unfold. That's why we take the time to understand your brand, your goals, and your audience. We listen, we learn, and we collaborate, weaving your story into the digital fabric of your project.</p>
        <p  class="text-xl max-w-4xl leading-loose">From web design that captivates, to app development that engages, and business consulting that guides, Nexa Interactives is your partner in the ever-evolving digital odyssey. We're not just here to build websites and applications; we're here to build your success.</p>
        <p  class="text-xl max-w-4xl leading-loose">Join us on this journey, and together, we'll navigate the digital landscape with vision, innovation, and excellence. Welcome to Nexa Interactives – Where Digital Excellence Meets Creativity.</p>
    </Container>
    <Container>
        <C2a title="Hourly Support Subscription" subtitle="Access Dedicated Assistance and Support at an Average Rate of $72.8 This Year" cta="Pay now" url="/" />
    </Container>
</template>
<script>
import ButtonLink from '@/components/buttons/ButtonLink.vue';
import ProfileWImage from '@/components/profile/ProfileWImage.vue'
import Trusted from '@/components/Trusted.vue';
import Projects from '@/components/Projects.vue';
import C2a from '@/components/C2a.vue';
import Container from '@/layouts/Container.vue';
import ProjectPreview from '@/components/ProjectPreview.vue';
import PlansFull from '@/components/PlansFull.vue';
import Testimonials from '@/components/Testimonials.vue';
export default {
    components: {
    ButtonLink,
    ProfileWImage,
    Trusted,
    Projects,
    C2a,
    Container,
    ProjectPreview,
    PlansFull,
    Testimonials
},
    data() {
        return {
            contents: [
                {
                    id: 1,
                    title: 'Research & planing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/star.svg'
                },
                {
                    id: 2,
                    title: 'Design & Development',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/code.svg'
                },
                {
                    id: 3,
                    title: 'SEO & Marketing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/zap-off.svg'
                }
            ],
            projects: [
                {
                    id: 1,
                    name: 'Flex Cleaning Service',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D6D3E2',
                    url: '',
                    image: '/assets/images/screen-fc.png',
                    stats: [{number: '5x', context: 'branches'}, {number: '700K', context: 'transactions'}]
                },
                {
                    id: 2,
                    name: 'Horn Daily',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D5CB97',
                    url: '',
                    image: '/assets/images/screen-hd.png',
                    stats: [{number: '200k', context: 'articles'}, {number: '150K', context: 'visits'}]
                },
                {
                    id: 3,
                    name: 'Nomad Lyrics',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#97D5B4',
                    url: '',
                    image: '/assets/images/screen-discover.png',
                    stats: [{number: '1.2M', context: 'streams'}, {number: '100K', context: 'visits'}]
                }
            ]
        }
    }
}
</script>
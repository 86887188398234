<template>
    <div class="flex items-center gap-4">
        <div v-if="image">
            <img :src="image" class="h-12 w-12 rounded-full" alt="profile picture">
        </div>
        <div>
            <div class="font-medium">{{ name }}</div>
            <div class="font-light">{{ title }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: String,
        title: String,
        image: String
    }
}
</script>
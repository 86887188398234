<template>
    <div class="bg-nomad-primary">
        <Container>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-6 gap-4 items-center">
                <div class="text-center flex flex-col items-center">
                    <img src="/assets/logo-accent.svg" class="w-20 my-6 mx-auto" title="Nexa Interactives"/>
                    <p class="text-lg font-light text-gray-300">At Nexa Interactives, we are passionate about crafting digital solutions that drive excellence. With a team of seasoned web developers, designers, and tech enthusiasts, we specialize in turning ideas into impactful online experiences.</p>
                </div>
                <div>
                    <ul class="text-gray-100  flex flex-col gap-3 ml-3 lg:m-10">
                        <li v-for="menu in menus" :key="menu.id"><router-link :to="menu.url" class="font-medium text-xl hover:text-nomad-light">{{ menu.text }}</router-link></li>
                    </ul>
                </div>
                <div>
                    <ul class="text-gray-100  flex flex-col gap-3 ml-3 lg:m-10">
                        <li v-for="menu in menusSub" :key="menu.id"><router-link :to="menu.url" class="font-medium text-xl hover:text-nomad-light">{{ menu.text }}</router-link></li>
                    </ul>
                </div>
                <div>
                    <ul class="text-white ml-3 mt-10 text-left flex flex-col gap-3">
                        <li><span class="icon-phone"></span> (202) 670-1716</li>
                        <li><span class="icon-mail"></span> support@nexainteractives.com</li>
                        <li><span class="icon-map"></span> Annandale, VA</li>
                        <li> Virginia</li>
                        <li> Washington, DC</li>
                        <li> Maryland</li>
                    </ul>
                </div>
            </div>
        </Container>
        <div class="text-center text-gray-100 py-3">&copy; 2023 Nexa Interactives, all rights reserved.</div>
    </div>
</template>
<script>
import Container from '@/layouts/Container.vue'
export default {
    components: {
        Container
    },
    data() {
        return {
            menus: [
                {
                    id: 1, 
                    text: 'Projects',
                    url: '/projects'
                },
                {
                    id: 2, 
                    text: 'Plans',
                    url: '/plans'
                },
                {
                    id: 3, 
                    text: 'Our Services',
                    url: '/projects'
                },
                {
                    id: 4, 
                    text: 'Hourly Subscriptions',
                    url: '/checkout'
                }
            ],
            menusSub: [
                {
                    id: 1, 
                    text: 'Contact us',
                    url: '/contact'
                },
                {
                    id: 2, 
                    text: 'About',
                    url: '/about'
                },
                {
                    id: 3, 
                    text: 'Terms & Conditions',
                    url: '/page/terms-conditions'
                },
                {
                    id: 4, 
                    text: 'Privacy Policy',
                    url: '/page/privacy'
                }
            ],
            
        }
    }
}
</script>
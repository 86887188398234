<template>
    <div class="bg-nomad-accent rounded-2xl flex flex-col lg:flex-row items-center p-10 lg:p-20 gap-4">
        <div  class="flex-1 grid gap-4">
            <h2 class="text-3xl lg:text-5xl font-bold">{{ title }}</h2>
            <p class="text-2xl font-light">{{ subtitle }}</p>
        </div>
        <div>
            <button-link :to="url">{{ cta }}</button-link>
        </div>
    </div>
</template>
<script>
import ButtonLink from './buttons/ButtonLink.vue';
export default {
    props: {
        title: String,
        subtitle: String,
        url: {
            type: String,
            default: '#'
        },
        cta: String
    },
    components: {
        ButtonLink
    }
}
</script>
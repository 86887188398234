<template>
    <div class="container">
        <h2 class="text-3xl font-medium ">Trusted by.</h2>
        <ul class="grid grid-cols-2 lg:grid-cols-4  gap-6">
            <li v-for="company in companies" class="border rounded-xl my-4 border-gray-200">
                <img :src="company.src" class="h-28 mx-auto" alt="trusted">
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            companies: [
                // {
                //     src: '/assets/images/trusted-nomad-fitness.png'
                // },
                {
                    src: '/assets/images/logo-flexcleaning.svg'
                },
                {
                    src: '/assets/images/logo-lanu.svg'
                },
                {
                    src: '/assets/images/logo-nomadlyrics.svg'
                },
                {
                    src: '/assets/images/logo-midwestrehabs.svg'
                },
                {
                    src: '/assets/images/logo-bluecrescent.svg'
                },
                {
                    src: '/assets/images/logo-wealthrevive.svg'
                }
            ]
        }
    }
}
</script>

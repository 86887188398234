<template>
    <div class="fixed w-full h-full bg-black/70 top-0 z-50 items-center flex flex-col" v-if="!modalHidden" >
        <button class="icon-x text-white w-16 h-16 m-3 rounded-full text-3xl bg-black"  @click.self="hideModal"></button>
        <div class="bg-nomad-light w-3/4 md:w-1/3 rounded-xl m-auto flex flex-col gap-6 p-3 ">
            <h1 class="text-3xl lg:text-5xl font-bold">In-Hurry</h1>
            <p class="text-xl lg:text-xl font-light">Our In-Hurry plan is the fastest way to get your website live. We'll build you a beautiful, minimalist site that's optimized for speed and user experience. This plan is perfect if you need a simple, yet effective online presence quickly.</p>
            <p>Estimated Launch <span class="font-bold text-lg">1 week</span></p>
            <p>Fixed price <span class="font-bold text-lg">$839</span></p>
            <div><router-link to="/contact" @click.prevent="bookCall" class="font-bold border rounded-full border-nomad-primary hover:bg-nomad-primary hover:text-nomad-light p-3">Book a call</router-link></div>
            <div class="h-4"></div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      modalHidden: false
    };
  },
  mounted() {
    this.checkModalVisibility();
    if (!this.modalHidden) {
      document.body.classList.add('overflow-hidden');
    }
  },
  beforeUnmount() {
    document.body.classList.remove('overflow-hidden');
  },
  methods: {
    bookCall() {
      this.hideModal();
      this.$router.push('/contact');
    },
    hideModal() {
      this.modalHidden = true;
      localStorage.setItem('modelTimeout', Date.now());
      document.body.classList.remove('overflow-hidden');
    },
    checkModalVisibility() {
      const lastHiddenTime = localStorage.getItem('modelTimeout');
      const currentTime = Date.now();

      if (lastHiddenTime && currentTime - lastHiddenTime < 2 * 24 * 60 * 60 * 1000) { // 2 days in milliseconds
        this.modalHidden = true;
      }
    }
  }
};
</script>

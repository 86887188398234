<template>
    <div class="grid grid-cols-1 lg:grid-cols-9 gap-10 max-w-7xl mx-auto">
        <div class="bg-gray-100 rounded-3xl  overflow-hidden lg:col-span-3 lg:row-span-2 mt-6 mx-auto">
            <div class="bg-gray-300 rounded-b-3xl p-6 text-center">
                <h3 class="text-3xl font-medium my-2">Starter Spark</h3>
                <p class="text-xl font-light">Ideal for beginners with essential requirements, our Spark Starter Plan provides fundamental services to establish a robust online presence.</p>
                <div>
                    <div class="mt-10 font-medium text-xl">$1,989</div>
                    <span>65% down payment required</span>
                </div>
                <router-link to="/checkout" class="my-3 rounded-3xl border-2 text-nomad-primary border-nomad-primary font-medium px-3 py-2 hover:bg-nomad-primary hover:text-nomad-accent transition-all duration-300  inline-block">Pay Now</router-link>
            </div>
            <div class="text-center">
                <ul class="text-left my-3 mx-4 text-nomad-primary  grid text-lg font-light gap-3">
                    <li class="flex gap-2" v-for="spark in sparks" :key="spark.id">
                        <div class="pt-1"><span class="icon-check-circle"></span></div>
                        <div class="flex-1 flex flex-col">
                            <div class="font-medium">{{ spark.text }}</div>
                            <div class="font-light text-gray-500">{{ spark.description }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bg-nomad-primary rounded-3xl overflow-hidden lg:col-span-3 lg:row-span-3 shadow-2xl">
            <div class="bg-nomad-pinnacle rounded-b-3xl p-6 text-center relative">
                <div class="uppercase bg-nomad-primary text-nomad-pinnacle from-nomad-light to-nomad-accent rounded-lg p-3 -mt-8 left-40 inline-block absolute ">Popular</div>
                <h3  class="text-3xl font-medium my-2 mt-10">Growth Gear</h3>
                <p class="text-2xl font-light">Upgrade to the Growth Plan for comprehensive features, perfect for clients with more extensive requirements.</p>
                <div>
                    <div class="mt-10 font-medium text-xl">$5,989</div>
                    <span>55% down payment required</span>
                </div>
                <router-link to="/checkout" class="my-3 rounded-3xl border-2 text-nomad-primary border-nomad-primary font-medium px-3 py-2 hover:bg-nomad-primary hover:text-nomad-accent transition-all duration-300 inline-block">Pay Now</router-link>
            </div>
            <div class="text-center">
                <ul class="text-left my-3 mx-4 text-nomad-accent  grid text-lg font-medium gap-3">
                    <li class="flex gap-2" v-for="spark in grows" :key="spark.id">
                        <div class="pt-1"><span class="icon-check-circle"></span></div>
                        <div class="flex-1 flex flex-col">
                            <div class="font-medium">{{ spark.text }}</div>
                            <div class="font-light text-white">{{ spark.description }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bg-gray-100 rounded-3xl  overflow-hidden lg:col-span-3 lg:row-span-2 mt-6 mx-auto">
            <div class="bg-gray-300 rounded-b-3xl p-6 text-center">
                <h3  class="text-3xl font-medium my-2">Pinnacle Pulse</h3>
                <p class="text-xl font-light">Our Pinnacle Pulse, the ultimate option, guarantees a comprehensive, premium experience with advanced features and top-priority support.</p>
                <div>
                    <div class="mt-10 font-medium text-xl">Starting $12k</div>
                    <span>45% down payment required</span>
                </div>
                <router-link to="/contact" class="my-3 rounded-3xl border-2 text-nomad-primary border-nomad-primary font-medium px-3 py-2 hover:bg-nomad-primary hover:text-nomad-accent transition-all duration-300  inline-block">Let's Talk</router-link>
            </div>
            <div class="text-center">
                <ul class="text-left my-3 mx-4 text-nomad-primary  grid text-lg font-medium gap-3 ">
                    <li class="flex gap-2" v-for="spark in pinnacles" :key="spark.id">
                        <div class="pt-1"><span class="icon-check-circle"></span></div>
                        <div class="flex-1 flex flex-col">
                            <div class="font-medium">{{ spark.text }}</div>
                            <div class="font-light text-gray-500">{{ spark.description }}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            sparks: [
                {
                    id: 1,
                    text: "Business Package",
                    description: "Our all-inclusive package to kickstart your online presence and success."
                },
                {
                    id: 1,
                    text: "Infrastructure setup",
                    description: "We handle the technical aspects, from web hosting to domain registration, to get your website online."
                },
                {
                    id: 1,
                    text: "Logo Design",
                    description: "Our designers create a unique logo that represents your brand's identity and professionalism."
                },
                {
                    id: 1,
                    text: "up to 3 templates to choose from",
                    description: "We offer a selection of website templates for you to pick the design that suits your brand best."
                },
                {
                    id: 1,
                    text: "Social Media Setup",
                    description: "We set up and optimize your social media profiles, making it easy to connect with your audience."
                },
                {
                    id: 1,
                    text: "Email Setup",
                    description: "Ensure an excellent user experience on all devices, including mobile phones and tablets."
                },
                {
                    id: 1,
                    text: "Basic SEO",
                    description: "We ensure that your website is search engine-friendly, helping potential customers find you online."
                },
                {
                    id: 1,
                    text: "Security Measures",
                    description: "Protect your website and customer data with robust security measures."
                }
            ],
            grows: [
                {
                    id: 1,
                    text: "Everthing in Spark",
                    description: "Our all-inclusive package to kickstart your online presence and success."
                },
                {
                    id: 1,
                    text: "Email Markting",
                    description: "Leverage the power of email campaigns to engage and convert your audience."
                },
                {
                    id: 1,
                    text: "Custom Template",
                    description: " Enjoy a unique website design tailored to your brand and vision."
                },
                {
                    id: 1,
                    text: "Up to 2 Third-Party Integrations",
                    description: "Seamlessly connect your website with external tools and platforms."
                },
                {
                    id: 1,
                    text: "E-commerce Analytics",
                    description: "Gain valuable insights into your online store's performance."
                },
                {
                    id: 1,
                    text: "Newsletter Signup",
                    description: "Attract and retain customers with a dedicated newsletter sign-up feature."
                },
                {
                    id: 1,
                    text: "Ad Manager Integration",
                    description: "Effortlessly manage and optimize your online advertisements."
                },
                {
                    id: 1,
                    text: "Google Ads Setup",
                    description: "Harness the potential of Google Ads to boost your online visibility."
                },
                {
                    id: 1,
                    text: "Advanced SEO",
                    description: "Go beyond basic SEO by offering advanced strategies, keyword research, and competitive analysis to improve search rankings."
                },
                {
                    id: 1,
                    text: "Performance Optimization",
                    description: "Optimize website speed, performance, and loading times for an enhanced user experience."
                },
                {
                    id: 1,
                    text: "2 months support",
                    description: "Enjoy priority access to our support team for rapid assistance and solutions to any inquiries or issues."
                },
                {
                    id: 1,
                    text: "Dedicated Account Manager *",
                    description: "Assign a dedicated account manager to provide personalized support and guidance throughout the client's journey."
                }
            ],
            pinnacles: [
                {
                    id: 1,
                    text: "Everthing in Gear",
                    description: "Propel your digital success to new heights with our all-inclusive \"Growth Gear\" package, plus an array of advanced services designed for top-tier performance:"
                },
                {
                    id: 1,
                    text: "Advanced SEO Strategy:",
                    description: "Elevate your search engine rankings and organic visibility with cutting-edge SEO techniques."
                },
                {
                    id: 1,
                    text: "Content Strategy and Creation",
                    description: "Fuel your online presence with high-quality, engaging content, including blog posts, articles, and multimedia."
                },
                {
                    id: 1,
                    text: "Comprehensive Social Media Management",
                    description: "Harness the full potential of social platforms with a comprehensive social media management strategy, including content creation, engagement, and audience growth."
                },
                {
                    id: 1,
                    text: "E-commerce Expansion",
                    description: "Expand your online store's capabilities with advanced e-commerce features, integrations, and personalized product recommendations."
                },
                {
                    id: 1,
                    text: "Advanced Analytics and Reporting",
                    description: "Dive deep into data-driven insights, track user behavior, and fine-tune your strategies for optimal results."
                },
                {
                    id: 1,
                    text: "Personalized Email Campaigns",
                    description: "Engage your audience with personalized email marketing campaigns, tailored to individual preferences and behavior."
                },
                {
                    id: 1,
                    text: "24/7 Priority Support",
                    description: "Enjoy priority access to our support team for rapid assistance and solutions to any inquiries or issues, up to 3 months without hourly subscription."
                },
                {
                    id: 1,
                    text: "Custom Strategy Sessions",
                    description: "Benefit from personalized strategy sessions to align your online presence with your long-term business goals."
                },
            ]
        }
    }
}
</script>
<template>
    <div class="bg-nomad-accent  w-full" v-bind:style="{ minHeight: '1200px' }">
        <div class="container p-8 lg:p-0 flex flex-col lg:flex-row gap-10 mx-auto items-center mb-20">
            <div class="flex gap-4 flex-col flex-1">
                <h1 class="text-6xl lg:text-8xl font-bold text-nomad-primary">Visionary Solutions: Navigating from Concept
                    to Success.</h1>
                <p class="text-light text-2xl">Tailored Solutions to Propel Your Success: Explore a Diverse Range of
                    Customized Services Designed to Elevate Your Business, From Innovative Design and Expert Development to
                    Strategic Architecture and Beyond.</p>
            </div>
            <div class="text-center flex-1">
                <img src="/assets/images/discover-mock-text.svg" class="m-auto border-3 rounded-xl border-white" alt=""
                    style="width: 32rem;">
            </div>
        </div>
    </div>
    <Container class="flex flex-col gap-8">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div v-for="service in services" class="rounded-xl bg-nomad-primary p-6 grid gap-3">
                <img :src="service.icon" alt="" class=" stroke-current  text-nomad-light">
                <div class="font-medium text-xl text-nomad-accent">{{ service.title }}</div>
                <div class="text-gray-300">{{ service.description }}</div>
            </div>
        </div>
    </Container>
    <Container>
        <C2a title="Hourly Support Subscription"
            subtitle="Access Dedicated Assistance and Support at an Average Rate of $72.8 This Year" cta="Pay now"
            url="/" />
    </Container>
</template>
<script>
import ButtonLink from '@/components/buttons/ButtonLink.vue';
import ProfileWImage from '@/components/profile/ProfileWImage.vue'
import Trusted from '@/components/Trusted.vue';
import Projects from '@/components/Projects.vue';
import C2a from '@/components/C2a.vue';
import Container from '@/layouts/Container.vue';
import ProjectPreview from '@/components/ProjectPreview.vue';
import PlansFull from '@/components/PlansFull.vue';
import Testimonials from '@/components/Testimonials.vue';
export default {
    components: {
        ButtonLink,
        ProfileWImage,
        Trusted,
        Projects,
        C2a,
        Container,
        ProjectPreview,
        PlansFull,
        Testimonials
    },
    data() {
        return {
            services: [

                {
                    id: 1,
                    title: 'Research & Planning',
                    description: 'Our cross-functional design, development, and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights, and your business goals.',
                    icon: '/assets/icons/aperture.svg'
                },
                {
                    id: 2,
                    title: 'Design & Development',
                    description: 'Our cross-functional design, development, and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights, and your business goals.',
                    icon: '/assets/icons/code.svg'
                },
                {
                    id: 3,
                    title: 'SEO & Marketing',
                    description: 'Enhance your online presence with our SEO and marketing services. Our cross-functional team combines design, development, and business analysis expertise with data-driven insights to help you achieve your business goals.',
                    icon: '/assets/icons/activity.svg'
                },
                {
                    id: 4,
                    title: 'Lead Generation',
                    description: 'Generate high-quality leads with our strategic lead generation services. Our team leverages data and technology to drive potential customers to your business, boosting your sales and growth.',
                    icon: '/assets/icons/database.svg'
                },
                {
                    id: 5,
                    title: 'Ads',
                    description: 'Maximize your online visibility and reach your target audience through effective advertising campaigns. Our experts create and manage ads that drive results and enhance your brand presence.',
                    icon: '/assets/icons/ads-icon.svg'
                },
                {
                    id: 6,
                    title: 'Third-Party Integration',
                    description: 'Seamlessly integrate third-party solutions and tools into your digital ecosystem. Our experienced team ensures that your systems work harmoniously to enhance efficiency and functionality.',
                    icon: '/assets/icons/integration-icon.svg'
                }


            ]
        }
    }
}
</script>
import "./bootstrap";

import { createApp, h, computed, ref } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import VueGtag from "vue-gtag-next";
import Layout from "@/layouts/App.vue";
import Home from "@/pages/Home.vue";
import Services from "@/pages/Services.vue";
import Plans from "@/pages/Plans.vue";
import Projects from "@/pages/Projects.vue";
import Checkout from "@/pages/Checkout.vue";
import CheckoutSuccess from "@/pages/checkout/Success.vue";
import CheckoutError from "@/pages/checkout/Error.vue";
import About from "@/pages/About.vue";
import NotFound from "@/pages/NotFound.vue";
import Contact from "@/pages/Contact.vue"
const routes = [
    { path: "/", component: Home, meta: { bg: "bg-nomad-light" } },
    { path: "/plans", component: Plans, meta: { bg: "bg-nomad-pinnacle" } },
    { path: "/services", component: Services, meta: { bg: "bg-nomad-accent" } },
    {
        path: "/projects",
        component: Projects,
        meta: { bg: "bg-nomad-sky" },
    },
    {
        path: "/checkout",
        component: Checkout,
        meta: { bg: "bg-nomad-greenish" },
    },
    {
        path: "/checkout/success",
        component: CheckoutSuccess,
        meta: { bg: "bg-nomad-greenish" },
    },
    {
        path: "/checkout/error",
        component: CheckoutError,
        meta: { bg: "bg-nomad-greenish" },
    },
    { path: "/about", component: About, meta: { bg: "bg-nomad-greenish" } },
    { path: "/contact", component: Contact, meta: { bg: "bg-nomad-greenish" } },
    { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

const app = createApp({
    setup() {
        const message = ref("Hello vue!");
        return {
            message,
        };
    },
    render: function (p) {
        return h(Layout);
    },
});
app.use(router);
app.use(VueGtag, {
    isEnabled: true,
    useDebugger: false,
    property: { id: "G-ENCX2WBNLN", params: { send_page_view: true } },
});
app.mount("#app");

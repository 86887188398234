<template>
    <div class="grid grid-cols-1 lg:grid-cols-9 gap-10 max-w-7xl mx-auto">
        <div class="bg-nomad-primary rounded-3xl  overflow-hidden lg:col-span-3 lg:row-span-2 mt-6 mx-auto">
            <div class="bg-nomad-light rounded-b-3xl p-6 text-center">
                <h3 class="text-3xl font-medium my-2">Starter Spark</h3>
                <p class="text-xl font-light">Ideal for starters with basic needs, our Essential Plan offers fundamental services for a strong online presence.</p>
                <div>
                    <div class="mt-10 font-medium text-xl">$1,989</div>
                    <span>45% down payment required</span>
                </div>
            </div>
            <div class="text-center pb-10">
                <ul class="text-center my-3 mx-2 text-nomad-light  grid text-xl font-medium gap-3">
                    <li>Architecture setup</li>
                    <li>Logo Design</li>
                    <li>Full customization</li>
                    <li>Basic SEO</li>
                    <li>Pick from 3 Themes</li>
                    <li>Logo design</li>
                </ul>
                <router-link to='/checkout' class="my-3 rounded-3xl border-2 text-nomad-light border-nomad-light font-medium px-3 py-2 hover:bg-nomad-light hover:text-nomad-primary transition-all duration-300">Pay Now</router-link>
            </div>
        </div>
        <div class="bg-nomad-primary rounded-3xl overflow-hidden lg:col-span-3 lg:row-span-3 shadow-2xl">
            <div class="bg-nomad-accent rounded-b-3xl p-6 text-center">
                <span class="uppercase">Popular</span>
                <h3  class="text-3xl font-medium my-2">Growth Gear</h3>
                <p class="text-2xl font-light">Upgrade to the Growth Plan for comprehensive features, perfect for clients with more extensive requirements.</p>
                <div class="hidden">
                    <div class="mt-10 font-medium text-xl">$3,989</div>
                    <span>55% down payment required</span>
                </div>
            </div>
            <div class="text-center  pb-10">
                <ul class="text-center my-3 mx-2 text-nomad-accent  grid text-lg font-medium gap-3">
                    <li>Everything in Starter Spark</li>
                    <li>Advanced SEO</li>
                    <li>Email Marketing</li>
                    <li>Mobile Optimization</li>
                    <li>Business Consultation</li>
                    <li>Third-party app setup*</li>
                </ul>
                <router-link to='/contact' class="my-3 rounded-3xl border-2 text-nomad-accent border-nomad-accent font-medium px-3 py-2 hover:bg-nomad-accent hover:text-nomad-primary transition-all duration-300">Let's Talk</router-link>
            </div>
        </div>
        <div class="bg-nomad-primary rounded-3xl  overflow-hidden lg:col-span-3 lg:row-span-2 mt-6 mx-auto">
            <div class="bg-nomad-pinnacle rounded-b-3xl p-6 text-center">
                <h3  class="text-3xl font-medium my-2">Pinnacle Pulse</h3>
                <p class="text-xl font-light">Our top-tier offering, the Premium Plan, ensures a complete, premium experience with advanced features and priority support.</p>
                <div class="hidden">
                    <div class="mt-10 font-medium text-xl">Starting $12k</div>
                    <span>65% down payment required</span>
                </div>
            </div>
            <div class="text-center  pb-10">
                <ul class="text-center my-3 mx-2 text-nomad-pinnacle  grid text-lg font-medium gap-3">
                    <li>Everything in Growth Gear</li>
                    <li>Personalized Consultation</li>
                    <li>Bespoke Solutions</li>
                    <li>Advanced Features</li>
                    <li>Basic SEO</li>
                </ul>
                <router-link to='/contact' class="my-3 rounded-3xl border-2 text-nomad-pinnacle border-nomad-pinnacle font-medium px-3 py-2 hover:bg-nomad-pinnacle hover:text-nomad-primary transition-all duration-300">Let's Talk</router-link>
            </div>
        </div>
    </div>
</template>
<template>
    <div>
      <button @click="isOpen = !isOpen" class="relative group focus:outline-none">
        <div class="relative flex overflow-hidden items-center justify-center rounded-full w-[50px] h-[50px] transform transition-all   duration-200">
          <div  class="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
            <div class="bg-nomad-primary h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10"></div>
            <div class="bg-nomad-primary h-[2px] w-7 rounded transform transition-all duration-300 group-focus:translate-x-10 delay-75"></div>
            <div class="bg-nomad-primary h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10 delay-150"></div>
            
            <div class="absolute items-center justify-between transform transition-all duration-500 top-2.5 -translate-x-10 group-focus:translate-x-0 flex w-0 group-focus:w-12">
              <div class="absolute bg-nomad-primary h-[2px] w-5 transform transition-all duration-500 rotate-0 delay-300 group-focus:rotate-45"></div>
              <div class="absolute bg-nomad-primary h-[2px] w-5 transform transition-all duration-500 -rotate-0 delay-300 group-focus:-rotate-45"></div>
            </div>
          </div>
        </div>
      </button>
  
      <!-- Your menu content goes here -->
      <div v-show="isOpen" class="bg-nomad-primary fixed top-0 left-0 w-64 h-screen shadow-lg p-4 transition-opacity duration-300 ease-in">
        <!-- Your menu items go here -->
        <ul>
            <li v-for="menu in menus" v-bind:key="menu.id" class="mx-4">
                <router-link  :to="menu.url" @click="togglePanel" class="block py-2 text-nomad-light font-medium text-lg hover:text-white">{{ menu.text }}</router-link>
            </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      menus: Array
    },
    data() {
      return {
        isOpen: false,
      };
    },
    methods: {
      togglePanel() {
        this.isOpen = ! this.isOpen
      }
    }
  };
  </script>
  
  <style>
  /* Your additional styles go here if needed */
  </style>
  
<template>
    <div>
        <Navigation />
        <router-view>

        </router-view>
        <Footer />
        <Model />
    </div>
</template>
<script>
import Navigation from '@/layouts/Navigation.vue'
import Footer from '@/layouts/Footer.vue'
import Model from '@/components/Model.vue';
export default {
  components: {
    Navigation,
    Footer,
    Model
  },
  created() {

  }

}
</script>
<template>
    <div class="bg-nomad-pinnacle  w-full" v-bind:style="{ minHeight: '1200px' }">
        <div class="container p-8 lg:p-0 flex flex-col lg:flex-row gap-10 mx-auto items-center mb-20">
            <div class="flex gap-4 flex-col flex-1">
                <h1 class="text-6xl lg:text-8xl font-bold text-nomad-primary">Tailored Solutions to Propel Your Success</h1>
                <p class="text-light text-2xl">Explore our range of tailored plans designed to meet your specific needs. Whether you're just starting or looking to elevate your digital presence, our plans offer a pathway to success. Choose the one that aligns with your goals and embark on a journey to digital excellence with Nexa Interactives.</p>
            </div>
            <div class="text-center flex-1">
                <img src="/assets/images/discover-mock.svg" class="m-auto border-3 rounded-xl border-white" alt=""
                    style="width: 32rem;">
            </div>
        </div>
    </div><Container>
        <C2a title="In-Hurry" subtitle="Our In-Hurry plan is the fastest way to get your website live. We'll build you a beautiful, minimalist site that's optimized for speed and user experience. This plan is perfect if you need a simple, yet effective online presence quickly." cta="Pay $839" url="/checkout" />
    </Container>
    <Container class="flex flex-col gap-8">
        <PlansFull />
    </Container>
    
</template>
<script>
import ButtonLink from '@/components/buttons/ButtonLink.vue';
import ProfileWImage from '@/components/profile/ProfileWImage.vue'
import Trusted from '@/components/Trusted.vue';
import Projects from '@/components/Projects.vue';
import C2a from '@/components/C2a.vue';
import Container from '@/layouts/Container.vue';
import ProjectPreview from '@/components/ProjectPreview.vue';
import PlansFull from '@/components/PlansFull.vue';
import Testimonials from '@/components/Testimonials.vue';
export default {
    components: {
    ButtonLink,
    ProfileWImage,
    Trusted,
    Projects,
    C2a,
    Container,
    ProjectPreview,
    PlansFull,
    Testimonials
},
    data() {
        return {
            contents: [
                {
                    id: 1,
                    title: 'Research & planing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/star.svg'
                },
                {
                    id: 2,
                    title: 'Design & Development',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/code.svg'
                },
                {
                    id: 3,
                    title: 'SEO & Marketing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/zap-off.svg'
                }
            ],
            projects: [
                {
                    id: 1,
                    name: 'Flex Cleaning Service',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D6D3E2',
                    url: '',
                    image: '/assets/images/screen-fc.png',
                    stats: [{number: '5x', context: 'branches'}, {number: '700K', context: 'transactions'}]
                },
                {
                    id: 2,
                    name: 'Horn Daily',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#D5CB97',
                    url: '',
                    image: '/assets/images/screen-hd.png',
                    stats: [{number: '200k', context: 'articles'}, {number: '150K', context: 'visits'}]
                },
                {
                    id: 3,
                    name: 'Nomad Lyrics',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals. being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    color: '#97D5B4',
                    url: '',
                    image: '/assets/images/screen-discover.png',
                    stats: [{number: '1.2M', context: 'streams'}, {number: '100K', context: 'visits'}]
                }
            ]
        }
    }
}
</script>
<template>
    <div class="error-page flex gap-3 flex-col my-48 text-center">
      <h1 class="text-nomad-accent font-bold  text-3xl">Oops, payment faild</h1>
      <p>It looks like your credit was not chartged, please try again.</p>
      <div><router-link to="/checkout" class="rounded-full border-2 border-nomad-primary py-3 px-4 inline-block mt-8 font-bold hover:bg-nomad-accent">Go back and fix it</router-link>
    </div></div>
  </template>
  
  <script>
  export default {
    name: 'CheckoutError',
  }
  </script>
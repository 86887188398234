<template>
    <router-link :to="url" class="border-2 px-6 py-3 border-nomad-primary rounded-3xl hover:bg-nomad-primary hover:text-nomad-light font-medium  items-center inline-block  transition-all duration-300 inline-block">
        <slot />
    </router-link>
</template>
<script>
export default {
    props: {
        url: {
            type: String,
            default: '#'
        }
    }
}
</script>
<template>
    <div class="error-page flex gap-3 flex-col my-48 text-center">
      <h1 class="text-green-600 font-bold  text-3xl">Payment Success</h1>
      <p class="text-xl max-w-xl mx-auto leading-loose">Your order has been placed. Keep an eye on your inbox for an email containing your order details and important next steps. We sincerely appreciate your trust in us and look forward to serving you.</p>
      <div><router-link to="/" class="rounded-full border-2 border-nomad-primary py-3 px-4 inline-block mt-8 font-bold hover:bg-nomad-accent">Go to Home</router-link>
    </div></div>
  </template>
  
  <script>
  export default {
    name: 'CheckoutSuccess',
  }
  </script>
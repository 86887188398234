<template>
    
    <div class="bg-nomad-sky  w-full" v-bind:style="{ minHeight: '1200px' }">
        <div class="container p-8 lg:p-0 flex flex-col lg:flex-row gap-10 mx-auto items-center mb-20">
            <div class="flex gap-4 flex-col flex-1">
                <h1 class="text-6xl lg:text-8xl font-bold text-nomad-primary">Navigating from Vision to Success: Our Portfolio of Achievements</h1>
                <p class="text-light text-2xl">Explore a selection of our standout projects that showcase our expertise and creativity. From web design to app development, these success stories highlight our commitment to delivering exceptional digital solutions.</p>
                <ul class="">
                    <li v-for="content in contents">
                        
                    </li>
                </ul>
            </div>
            <div class="text-center flex-1">
                <img src="/assets/images/screen-discover-wire-blank.svg" class="m-auto border-3 rounded-xl border-white" alt=""
                    style="width: 32rem;">
            </div>
        </div>
    </div>
    <Container class="flex flex-col gap-8">
        <ProjectPreview v-for="project in projects" :name="project.name" :description="project.description" :image="project.image"  />
    </Container>
    <Container>
        <C2a title="Hourly Support Subscription" subtitle="Access Dedicated Assistance and Support at an Average Rate of $72.8 This Year" cta="Pay now" url="/" />
    </Container>
</template>
<script>
import ButtonLink from '@/components/buttons/ButtonLink.vue';
import ProfileWImage from '@/components/profile/ProfileWImage.vue'
import Trusted from '@/components/Trusted.vue';
import Projects from '@/components/Projects.vue';
import C2a from '@/components/C2a.vue';
import Container from '@/layouts/Container.vue';
import ProjectPreview from '@/components/ProjectPreview.vue';
import PlansFull from '@/components/PlansFull.vue';
import Testimonials from '@/components/Testimonials.vue';
export default {
    components: {
    ButtonLink,
    ProfileWImage,
    Trusted,
    Projects,
    C2a,
    Container,
    ProjectPreview,
    PlansFull,
    Testimonials
},
    data() {
        return {
            contents: [
                {
                    id: 1,
                    title: 'Research & planing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/star.svg'
                },
                {
                    id: 2,
                    title: 'Design & Development',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/code.svg'
                },
                {
                    id: 3,
                    title: 'SEO & Marketing',
                    description: 'Our cross-functional design, development and business analysis team is ready to take on your next project, being guided by in-depth tech expertise, data-driven insights and your business goals.',
                    icon: '/assets/icons/zap-off.svg'
                }
            ],
            projects: [
            {
                    id: 1,
                    name: 'Flex Cleaning Service',
                    description: "Flex Cleaning's transformation journey includes a modern website, data insights, and targeted reach. We crafted a website that reflects their professionalism and implemented data tools for strategic decisions. Our targeted reach system maximizes their online presence.",
                    color: '#D6D3E2',
                    url: '',
                    image: '/assets/images/screen-fc.png',
                    stats: [{number: '5x', context: 'branches'}, {number: '700K', context: 'transactions'}]
                },
                {
                    id: 2,
                    name: 'Horn Daily',
                    description: "We're proud to present Horn Daily's Arabic website, a portal that delivers the latest news, stories, and updates to Arabic-speaking audiences. Our website design ensures a seamless reading experience, offering comprehensive news coverage in a visually appealing and culturally sensitive format.",
                    color: '#D5CB97',
                    url: '',
                    image: '/assets/images/screen-hd.png',
                    stats: [{number: '200k', context: 'articles'}, {number: '150K', context: 'visits'}]
                },
                {
                    id: 3,
                    name: 'Nomad Lyrics',
                    description: "Horn Daily's digital journey mirrors Nomad Lyrics in its multifaceted approach. Our work spans a visually captivating Arabic website, engaging newsletters, and a seamless podcast feed system. Discover news, stay informed with newsletters, and enjoy audio content. Horn Daily is your holistic digital source.",
                    color: '#97D5B4',
                    url: '',
                    image: '/assets/images/screen-discover.png',
                    stats: [{number: '1.2M', context: 'streams'}, {number: '100K', context: 'visits'}]
                }
            ]
        }
    }
}
</script>
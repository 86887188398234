<template>
    <div class="z-50 text-center w-full sticky top-0" :class="bg">
        <div class="container mx-auto border-1 h-28 flex items-center gap-4 p-8 lg:p-0">
            <div class="flex-initial">
                <router-link to="/" >
                    <img src="/assets/logo.svg" alt="Nomad Interactives" class="h-20">
                </router-link>
            </div>
            <div class="flex-1 hidden lg:block">
                <ul class="flex gap-6 font-medium text-xl ml-20">
                    <li v-for="menu in menus" :key="menu.id"><router-link :to="menu.url" class="text-nomad-primary hover:text-black  group">{{ menu.text }} <hr class="border border-nomad-primary  mx-auto group-hover:w-2/3  animate-line-widen transition-all  duration-500 w-0 opacity-0 group-hover:opacity-90" /></router-link></li>
                </ul>
            </div>
            <div class="flex flex-grow gap:3 lg:gap-4 text-right justify-end">
                <button-link url="/contact">Let's Talk</button-link>
                <Menu class="block lg:hidden" :menus="menus" />
            </div>
        </div>
    </div>
</template>
<script>
import Menu from '@/components/buttons/Menu.vue'
import ButtonLink from '../components/buttons/ButtonLink.vue';
export default {
    components: {
        Menu,
        ButtonLink
    },
    data() {
        return {
            bg: 'bg-nomad-light',
            menus: [
                {
                    id: 1,
                    text: 'Services',
                    url: '/services'
                },
                {
                    id: 2,
                    text: 'Plans',
                    url: '/plans'
                },
                {
                    id: 4,
                    text: 'About',
                    url: '/about'
                },
                {
                    id: 3,
                    text: 'Contact',
                    url: '/contact'
                },

            ]
        }
    },
    created() {
       //console.log(this.$route.meta);
        //this.bg = this.$route.meta.bg ?? 'bg-nomad-light'
        this.$watch(
            ()=> this.$route.meta,
            () => {
                console.log(this.$route.meta);
                this.bg = this.$route.meta.bg ?? 'bg-nomad-light'
            }
        )
    },
    methods: {

    }
}
</script>